<template>
  <van-nav-bar
    :title="title"
  />
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="margin:15px 0 0 0;padding:15px;background:#fff;position: relative;">
        <van-tag :type="item.amount > 0 ? 'primary' : 'danger'" size="large">&yen; {{ item.amount }}</van-tag>
        <p style="margin:7px 0 0 0;">
          <small>{{ item.memo }}</small>
        </p>
        <p style="color:#999;margin:7px 0 0 0;">
          <small>{{ item.createTime }}</small>
        </p>
        <div v-if="item.type === 'WITHDRAWAL'" style="position: absolute;right: 20px;top: 35px;">
          <van-button size="mini" type="primary" @click="showPopup(item)">查看提现状态</van-button>
        </div>
      </div>
    </template>
  </van-list>
  <van-popup v-model:show="show" class="app-fund-popup">
    <div v-if="!statusLoading">
      <div v-if="status === 'CREATE_SUCCESS'">
        <van-icon name="clock-o" size="3rem" color="#1989fa" />
        <div class="title">提现业务单受理成功</div>
        <span>请您耐心等待</span>
      </div>
      <div v-if="status === 'SUCCESS'">
        <van-icon name="passed" size="3rem" color="#1989fa" />
        <div class="title">提现金额已到账</div>
      </div>
      <div v-if="status === 'FAIL'">
        <van-icon name="close" size="3rem" color="#f2003c" />
        <div class="title">提现失败</div>
        <span>原因：{{reason}}</span>
      </div>
      <div v-if="status === 'REFUND'">
        <van-icon name="close" size="3rem" color="#f2003c" />
        <div class="title">提现业务单已被退票</div>
        <span>原因：{{reason}}</span>
      </div>
      <div v-if="status === 'CLOSE'">
        <van-icon name="close" size="3rem" color="#f2003c" />
        <div class="title">提现业务单已被关单</div>
        <span>原因：{{reason}}</span>
      </div>
      <div v-if="status === 'INIT'">
        <van-icon name="more-o" size="3rem" color="#1989fa" />
        <div class="title">提现业务单已创建</div>
      </div>
      <div v-if="status === 'ERROR'">
        <van-icon name="close" size="3rem" color="#f2003c" />
        <div class="title">{{reason}}</div>
      </div>
    </div>
    <div  v-if="statusLoading">查询中...</div>
  </van-popup>
</template>

<script>
import { toRefs, ref, reactive, onMounted, inject, watch } from 'vue'
export default {
  components: {
  },
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRoute = inject('useRoute')
    const show = ref(false)
    const state = reactive({
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      userId: Cookies.get('userId'),
      storeId: Cookies.get('storeId'),
      status: '',
      reason: '',
      statusLoading: false
    })
    const getData = () => {
      const param = {
        pageNum: state.page,
        userId: state.userId
      }
      if (state.storeId) {
        param.storeId = state.storeId
      }
      post('/fund.list', param).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      })
    }
    watch(() => useRoute.query, (to, previous) => {
    })
    const init = () => {
    }
    const showPopup = (item) => {
      console.log(item)
      show.value = true
      state.statusLoading = true
      withdraw(item)
    }
    const withdraw = (item) => {
      post('/querySubWithdraw', {
        storeId: state.storeId,
        fundId: item.fundId
      }).then(res => {
        state.statusLoading = false
        if (res.data.status) {
          state.status = res.data.status
          state.reason = res.data.reason
        } else {
          state.status = 'ERROR'
          state.reason = res.data
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      show,
      showPopup
    }
  }
}
</script>

<style>
  .app-fund-popup{
    width: 50vw;
    border-radius: 10px;
    padding: 16px;
    text-align: center;
  }
  .app-fund-popup .title{
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }
</style>
